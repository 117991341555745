<template>
  <ul class="menu-nav">
    <!-- <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
    >
      <a
        href="#"
        class="menu-link menu-toggle"
        style="background: #2737c1; border-width: 1px; boder-radius: 3px"
      >
        <i class="text-white menu-icon flaticon2-architecture-and-city"></i>
        <span class="menu-text text-uppercase text-white">
          selceione a sede
        </span>
        <i class="text-white menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul
          v-for="(lista, index) in lista_filiais_autorizadas"
          :key="index"
          class="menu-subnav"
        >
          <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item">
            <a class="menu-link" @click="redirect(lista.id)">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text"> {{ lista.filial }} </span>
            </a>
          </li>
        </ul>
      </div>
    </li> -->

    <router-link
      to="/cursos"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="  "></i>
          <span class="menu-text">Pagina Incial</span>
        </a>
      </li>
    </router-link>
    <!-- <router-link
      to="/matriculas"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-folder-1"></i>
          <span class="menu-text">matriculas</span>
        </a>
      </li>
    </router-link> -->
<!-- 
    <router-link
      to="/permissoes"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon-interface-5"></i>
          <span class="menu-text">permissoes</span>
        </a>
      </li>
    </router-link> -->
    <!-- <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-chalkboard-teacher"></i>
          <span class="menu-text">Pagina Incial</span>
        </a>
      </li>
    </router-link> -->

  
    <!-- gerenciamento -->


    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren('/custom-pages'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon far fa-user"></i>
        <span class="menu-text"> gerenciamento </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            to="/historico"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> historico </span>
              </a>
            </li>
          </router-link>
            <router-link
            to="/funcionario"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> funcionario </span>
              </a>
            </li>
          </router-link>
          

        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",

  data() {
    return {
      sede_id: "0",
    };
  },
  created() {

  },
  watch: {
    // sede_id: function () {
    //   this.$store.dispatch("vincularSede", this.sede_id);
    // },
  },
  computed: {
    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

  
  },
};
</script>
